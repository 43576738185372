import React from 'react';
import { Label, FormGroup } from 'reactstrap';
import {default as RcSwitch} from 'rc-switch';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { When } from 'react-if';
import 'rc-switch/assets/index.css';
import './switch.sass';

const Switch = ({ id, label, onChange, floatRight, floatLeft, revert, bold, ...rest}) => (
    <FormGroup className={classnames({ 'float-right': floatRight, 'float-left': floatLeft }) }>
        <When condition={!revert}>
            <Label for={id} className={classnames({ 'font-weight-bold': bold })}>
                {label}
            </Label>
            &nbsp;
        </When>
        <RcSwitch id={id} onChange={onChange} checkedChildren="Sim" unCheckedChildren="Não" style={{width: '50px'}} {...rest}/>
        <When condition={revert}>
            &nbsp;
            <Label for={id} className={classnames({ 'font-weight-bold': bold })}>
                {label}
            </Label>
        </When>
    </FormGroup>
);

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([(PropTypes.string, PropTypes.node)]).isRequired,
  onChange: PropTypes.func.isRequired,
  floatLeft: PropTypes.bool,
  floatRight: PropTypes.bool,
};

Switch.defaultProps = {
    floatLeft: false,
    floatRight: false
};

export default Switch;